import styled from "styled-components"
import theme from "styles/theme-main"

export const SvgWrapper = styled.svg`
  color: inherit;
  fill: currentColor;
  width: inherit;
  height: inherit;
  position: relative;
`

export const IconToolTip = styled.div`
  position: absolute;
  text-align: center;
  width: auto;
  font-size: 12px;
  color: ${theme.colors.textSecondary};
  width: 130px;
  height: 30px;
  line-height: 30px;
  background-color: #ffffff;
  border: 1px solid ${theme.colors.border};
  bottom: 48px;
  left: -43px;
  padding: 0 9px 0 9px;
  z-index: 9;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
`

export const ToolTipWrapper = styled.div`
  color: inherit;
  fill: currentColor;
  width: inherit;
  height: inherit;
  position: absolute;
  overflow: visible;
  display: flex;
`
