/**
 * App routes - All main routes for the web app are defined here
 * Each route has a component and a layout component (Admin / Manager / Main / Account / Welcome )
 * We use Loadable and dynamic imports for code splitting. This creates different js files (chunks) for every component
 * we choose to load in this way and helps with the performance of the application.
 * The webpackChunkName comment helps with naming these chunks to something readable which is very useful when debugging Sentry errors.
 */

import React, { Component } from "react"
import Loadable from "react-loadable"
import AppRoute from "components/AppRoute"
import { Switch } from "react-router-dom"
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"
import { isCandidXMode } from "utils/productUtils"
import { getHomeRoute } from "utils/utils"

import AppLoader from "components/AppLoader"

const MainLayout = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MainLayout" */ "layouts/MainLayout/container"),
  loading: AppLoader,
  delay: 300,
})

const RoleLayout = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RoleLayout" */ "layouts/RoleLayout/container"),
  loading: AppLoader,
  delay: 300,
})

const FullWidthLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "FullWidthLayout" */ "layouts/FullWidthLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const MainRootLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MainRootLayout" */ "layouts/MainRootLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const EventLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EventLayout" */ "layouts/EventLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const RolesLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RolesLayout" */ "layouts/RolesLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const EventsLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EventLayout" */ "layouts/EventsLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ManagerLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ManagerLayout" */ "layouts/ManagerLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ManagerAdminLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ManagerAdminLayout" */ "layouts/ManagerAdminLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const AdminLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AdminLayout" */ "layouts/AdminLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const AccountLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountLayout" */ "layouts/AccountLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const WelcomeLayout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "WelcomeLayout" */ "layouts/WelcomeLayout/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: state =>
    getHomeRoute(state.user, state.data && state.data.organization),
  // If selector is true, wrapper will not redirect
  authenticatedSelector: state => Boolean(state.user.id),
  allowRedirectBack: false,
  // A nice display name for this check
  wrapperDisplayName: "UserIsAuthenticated",
  //redirectAction: routerActions.replace,
  // Returns true if the user auth state is loading
  authenticatingSelector: state => state.user.loading,
  // Render this component when the authenticatingSelector returns true
  AuthenticatingComponent: AppLoader,
})

// const locationHelper = locationHelperBuilder({});

// const userIsNotAuthenticated = connectedRouterRedirect({
//   // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
//   // redirectPath: (state, ownProps) => getHomeRoute(state.user),
//   redirectPath: (state) => getHomeRoute(state.user, state.data && state.data.organization),
//   // This prevents us from adding the query parameter when we send the user away from the login page
//   allowRedirectBack: false,
//   // If selector is true, wrapper will not redirect
//   // So if there is no user data, then we show the page
//   authenticatedSelector: state => !Boolean(state.user.id),
//   // A nice display name for this check
//   wrapperDisplayName: "UserIsNotAuthenticated"
//   // redirectAction: routerActions.replace
// });

// Load Routes for code splitting
const HomeRoute = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeRoute" */ "routes/Home/container"),
  loading: AppLoader,
  delay: 300,
})

const UIRoute = Loadable({
  loader: () => import(/* webpackChunkName: "U(IRoute" */ "routes/UI"),
  loading: AppLoader,
  delay: 300,
})

const LoginRoute = Loadable({
  loader: () =>
    import(/* webpackChunkName: "LoginRoute" */ "routes/Login/container"),
  loading: AppLoader,
  delay: 300,
})

const OAuthRoute = Loadable({
  loader: () =>
    import(/* webpackChunkName: "OAuthRoute" */ "routes/OAuth/container"),
  loading: AppLoader,
  delay: 300,
})

const ForgotPassword = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ForgotPassRoute" */ "routes/ForgotPassword/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const MagicPassword = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MagicPasswordRoute" */ "routes/MagicPassword/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ScreenCandidates = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ScreenCandidatesRoute" */ "routes/ScreenCandidates/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const CallbackCompleted = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CallbackCompletedRoutee" */ "routes/CallbackCompleted/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const OnBoarding = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "OnBoardingRoute" */ "routes/OnBoarding/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ResetPassword = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ResetPassRoute" */ "routes/ResetPassword/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFoundRoute" */ "routes/NotFound"),
  loading: AppLoader,
  delay: 300,
})

const SignUp = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SignUpRoute" */ "routes/SignUp/container"),
  loading: AppLoader,
  delay: 300,
})

const Terms = Loadable({
  loader: () => import(/* webpackChunkName: "TermsRoute" */ "routes/Terms"),
  loading: AppLoader,
  delay: 300,
})

const AdminClients = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AdminClientsRoute" */ "routes/AdminClients"),
  loading: AppLoader,
  delay: 300,
})

const Policy = Loadable({
  loader: () => import(/* webpackChunkName: "PolicyRoute" */ "routes/Policy"),
  loading: AppLoader,
  delay: 300,
})

const ScheduleUser = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "InterviewUserRoute" */ "routes/ScheduleUser/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const EventView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EventViewRoute" */ "routes/EventView/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const VerifyUser = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "VerifyUserRoute" */ "routes/VerifyUser/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ApplyAnonymous = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ApplyAnonymousRoute" */ "routes/ApplyAnonymous/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ApplyAnonymousPortal = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ApplyAnonymousPortalRoute" */ "routes/ApplyAnonymousPortal/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ListRoles = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ListRolesRoute" */ "routes/ListRoles/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const ListEvents = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ListEventsRoute" */ "routes/ListEvents/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const RedirectLink = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RedirectLinkRoute" */ "routes/RedirectLink/index"
    ),
  loading: AppLoader,
  delay: 300,
})

const LoadApplication = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "LoadApplicationRoute" */ "routes/LoadApplication/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const Applications = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ApplicationsRoute" */ "routes/Applications/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const PreviewAnonymous = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PreviewAnonymousRoute" */ "routes/Preview/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const VerifyEmail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "VerifyEmailRoute" */ "routes/VerifyEmail/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const Profile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ProfileRoute" */ "routes/Profile/container"),
  loading: AppLoader,
  delay: 300,
})

const Account = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ProfileRoute" */ "routes/Account/container"),
  loading: AppLoader,
  delay: 300,
})

const Settings = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SettingsRoute" */ "routes/Settings/container"),
  loading: AppLoader,
  delay: 300,
})

const Manager = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ManagerRoute" */ "routes/HiringManager/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "AdminRoute" */ "routes/Admin"),
  loading: AppLoader,
  delay: 300,
})

const EventCheckInView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EventCheckInRoute" */ "routes/EventCheckIn/container"
    ),
  loading: AppLoader,
  delay: 300,
})

const EventPublicCheckInView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EventPublicCheckInRoute" */ "routes/EventPublicCheckIn/container"
    ),
  loading: AppLoader,
  delay: 300,
})

/**
 * get the layout based on the user type
 * Useful when a route belongs to more than one userTypes
 * @param user
 * @returns {*}
 */
export const getLayoutBasedOnUserType = function(user) {
  switch (user.userType) {
    case "CANDIDATE":
      return MainLayout
    case "HIRING_MANAGER_ADMIN":
      return ManagerAdminLayout
    case "HIRING_MANAGER":
      return ManagerLayout
    case "ADMIN":
      return AdminLayout
    default:
      return MainLayout
  }
}

class AppRoutes extends Component {
  render() {
    const LayoutBasedOnUserType = getLayoutBasedOnUserType(this.props.user)
    return (
      <React.Fragment>
        <Switch>
          <AppRoute
            path={"/"}
            exact
            component={HomeRoute}
            layout={MainLayout}
          />

          <AppRoute
            path={"/ui"}
            exact
            component={UIRoute}
            layout={MainLayout}
          />

          {!isCandidXMode() && (
            <AppRoute
              path={"/signup"}
              exact
              component={SignUp}
              layout={WelcomeLayout}
            />
          )}

          <AppRoute
            path={"/profile"}
            component={userIsAuthenticated(Profile)}
            layout={MainLayout}
          />

          <AppRoute
            path={"/account"}
            component={userIsAuthenticated(Account)}
            layout={LayoutBasedOnUserType}
          />

          <AppRoute
            path={"/settings"}
            component={userIsAuthenticated(Settings)}
            layout={LayoutBasedOnUserType}
          />

          <AppRoute
            path={"/terms"}
            exact
            component={Terms}
            layout={MainLayout}
          />

          <AppRoute
            path={"/clients"}
            exact
            component={AdminClients}
            layout={AdminLayout}
          />

          <AppRoute
            path={"/policy"}
            exact
            component={Policy}
            layout={MainLayout}
          />

          <AppRoute
            path={"/verify/:token"}
            exact
            component={VerifyUser}
            layout={MainLayout}
          />

          <AppRoute
            path={"/interview/:token"}
            exact
            component={ScheduleUser}
            layout={EventLayout}
          />

          <AppRoute
            path={"/event/:eventId"}
            exact
            component={EventView}
            params={{ event: true }}
            layout={EventLayout}
          />
          <AppRoute
            path={"/event/:eventId/qr-check-in"}
            exact
            component={EventPublicCheckInView}
            layout={EventLayout}
          />
          <AppRoute
            path={"/event/:eventId/check-in"}
            exact
            component={userIsAuthenticated(EventCheckInView)}
            layout={EventLayout}
          />

          <AppRoute
            path={"/roles"}
            exact
            component={ListRoles}
            layout={RolesLayout}
          />

          <AppRoute
            path={"/events"}
            exact
            component={ListEvents}
            layout={EventsLayout}
          />

          <AppRoute
            path={"/job/:link"}
            exact
            component={RedirectLink}
            layout={MainLayout}
          />

          <AppRoute
            path={"/application"}
            exact
            component={LoadApplication}
            layout={MainRootLayout}
          />

          <AppRoute
            path={"/applications"}
            exact
            component={userIsAuthenticated(Applications)}
            layout={FullWidthLayout}
          />

          <AppRoute
            path={"/applya/:roleId"}
            exact
            component={ApplyAnonymous}
            layout={RoleLayout}
          />

          <AppRoute
            path={"/apply/:roleId"}
            exact
            component={ApplyAnonymousPortal}
            layout={RoleLayout}
          />

          <AppRoute
            path={"/applyp/:roleId"}
            exact
            component={ApplyAnonymous}
            layout={RoleLayout}
          />

          <AppRoute
            path={"/preview/:roleId"}
            exact
            component={PreviewAnonymous}
            layout={RoleLayout}
          />

          <AppRoute
            path={"/verify-email/:token"}
            exact
            component={VerifyEmail}
            layout={MainLayout}
          />

          <AppRoute
            path={"/login"}
            exact
            component={LoginRoute}
            layout={AccountLayout}
          />

          <AppRoute
            path={"/oauth"}
            exact
            component={OAuthRoute}
            layout={AccountLayout}
          />

          <AppRoute
            path={"/forgot-password"}
            exact
            component={ForgotPassword}
            layout={AccountLayout}
          />
          <AppRoute
            path={"/magic-password"}
            exact
            component={MagicPassword}
            layout={AccountLayout}
          />
          <AppRoute
            path={"/screen"}
            exact
            component={ScreenCandidates}
            layout={WelcomeLayout}
          />
          <AppRoute
            path={"/finished"}
            exact
            component={CallbackCompleted}
            layout={AccountLayout}
          />
          <AppRoute
            path={"/submitted"}
            exact
            component={CallbackCompleted}
            layout={AccountLayout}
          />
          <AppRoute
            path={"/on-boarding/:token"}
            exact
            component={OnBoarding}
            layout={AccountLayout}
          />
          <AppRoute
            path={"/reset-password/:token"}
            exact
            component={ResetPassword}
            layout={AccountLayout}
          />

          <AppRoute
            path={"/manager"}
            component={userIsAuthenticated(Manager)}
            layout={ManagerLayout}
          />

          <AppRoute
            path={"/admin"}
            component={userIsAuthenticated(Admin)}
            layout={AdminLayout}
          />

          <AppRoute
            path={"/clients"}
            component={userIsAuthenticated(Admin)}
            layout={AdminLayout}
          />

          <AppRoute component={NotFound} layout={MainLayout} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default AppRoutes
